import React from 'react';
import SEO from '../components/common/seo';
import Card from '../components/Card';

const RulesPage = () => (
  <>
    <SEO title="Rules" />
    <div style={{ marginBottom: `1.45rem` }} />
    <div>
      <h1>Rules & Information</h1>
      <Card
        body={
          <div>
            <p>
              Join the <a href="https://numerator.slack.com/app_redirect?channel=hackday">#hackday</a>{' '}
              slack channel to help connect with people and teams.
            </p>
            <p>
              Have an idea that you need help or want a team to work on? Great, <a href="/current-hackday">
                submit an idea</a> with a description of the hack and the type of help you think is
              needed. Please be prepared to spend a bit of time explaining and/or selling
              the idea to interested people.
            </p>
            <p>
              Looking for an idea or team? Browse the <a href="/current-hackday">Hack Ideas</a>{' '}page
              to find all the ideas people have and teams forming around those ideas. Find and open
              idea/team you like and press "Join Hack". Don't forget to reach out to the team to let
              them know you are interested.
            </p>
            <p>
              Please start planning your hacks and teams early and make sure to review your hack
              ideas with your manager by Friday before Hack Day starts.
            </p>
          </div>
        }
      >
        <h2>Hack Day Ideas and Teams</h2>
      </Card>
      <Card
        body={
          <div>
            <p>
              Every team will be expected to provide a deliverable for their hacks. Success,
              failure, anything in between, share what you explored and what you learned. We have a
              few different options available for you to tailor audience and format to what you
              think is the best fit for your hack. All presentations are 7 minutes long and a rough
              guide if you want it is available at{' '}
              <a href="https://go.infoscoutinc.com/HackDayTemplate">go/HackDayTemplate</a>.
            </p>
            <h3>
              Written Summary (
              <a href="https://docs.google.com/document/d/1Fn0TQauKctTgbuXUjYMbNcQEO44H2aqtRlOS3PYyiwA/edit">
                template
              </a>
              )
            </h3>
            <p>
              For Academic/Learning hacks, instead of a presentation, you will submit a short
              written piece about what you study and/or learned during Hack Day. Note: not eligible
              for prizes. Please submit written summary to
              {' '}<a href="mailto:mark.delcambre@numerator.com">Mark Delcambre</a> by the Friday
              following the Hack Day.
            </p>
            <h3>
              Present to Your Own Core Team
            </h3>
            <p>
              Each Hack Days team will share out their work with their respective teams.  Ideal for
              hacks that are narrowly focused on your team's general operational areas. Note: not
              eligible for prizes.
            </p>
            <h3>
              Prize Contenders
            </h3>
            <p>
              Present to other Hack Days competitors,
              Judges, and a wider audience. Judges will select winners from this group according to
              our <a href="/rubric">rubric</a>. Winners will be announced via email and shout out at the
              next All Hands. Additional hacks may be selected as a Judges Choice to be highlighted in
              the winning announcement email and at the next All Hands.
            </p>
            <p>
              Prize Contenders will be recorded for everyone to watch if they are unable to attend
              the live demo.
            </p>
          </div>
        }
      >
        <h2>Presentations</h2>
      </Card>
      <Card
        body={
          <div>
            <ul>
              <li><strong>When:</strong> 72 hour hack day</li>
              <li><strong>Demos:</strong> Two demo rounds will take place in the following
                weeks (see the section above).</li>
              <li><strong>Teams:</strong> Solo hacks are allowed although team hacks are encouraged.
                Teaming Product and Engineering with Sales and Services tends to lead to successful
                high-impact hacks. Keep to 5 people max.</li>
            </ul>
          </div>
        }
      >
        <h2>Hack Day Format</h2>
      </Card>
      <Card
        body={
          <div>
            <p>If you are going to pursue a centered around Machine Learning, please reach out to
              {' '}<a href="mailto:mark.delcambre@numerator.com">Mark Delcambre</a>. We can help get you
              access to resources (e.g. GPU) to help complete the project and help frame the hack to
              be most successful.
            </p>
          </div>
        }
      >
        <h2>Machine Learning Hacks</h2>
      </Card>
    </div>
  </>
);

export default RulesPage;
